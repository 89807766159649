import React from "react"
import PropTypes from "prop-types"

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Components
import { Link, graphql } from "gatsby"
import SEO from "@/components/seo";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import MenuContextProvider from "@/context/menu-context";
import HeaderTwo from "@/components/header-two";
import BlogCard from "@/components/blog-card";
import BlogSidebar from "@/components/blog-sidebar";


const Tags = ({ pageContext, data }) => {

  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const posts = edges.map(x => x.node);
  const postCount = `${totalCount} Post${totalCount === 1 ? "" : "s"}`
  let title = `Tag | ${tag} | Blog`;
  return (
    <MenuContextProvider>
      <Layout PageTitle={title}>
        <SEO title={title} />
        <HeaderTwo />
        <PageBanner title={`Tag`} name={tag} />
        <div className="commonSection text-center"></div>
        <Container>
          <Row>
            <Col lg={12}>
              <div>
                <h5 className="text-center" style={{ fontSize: "16px" }}>{postCount}</h5>
                <h1 className="blog-tags-title text-center">{tag}</h1>
              </div>
            </Col>
          </Row>
          <Row>
            {
              posts.map(post => {
                return (
                  <Col lg={4} sm={12} md={6} key={post.fields.slug}>
                    <article itemScope itemType="http://schema.org/Article">
                      <BlogCard post={post} />
                    </article>
                  </Col>
                )
              })
            }
          </Row>
        </Container>
        <Footer />
      </Layout>
    </MenuContextProvider>
  )
}

export default Tags
export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`