import React from "react";
import { Link } from "gatsby";

const BlogCard = ({ post }) => {
  return (
    <div className="latestBlogItem">
      <div className="lbi_thumb">
        {/* <img src={image} alt={title} /> */}
      </div>
      <div className="lbi_details">
        <h2>
          <Link to={`/blog${post.fields.slug}`}>{post.frontmatter.title}</Link>
        </h2>
        <span className="lbid_excerpt">
          {post.excerpt}
        </span>
        <div>
          <Link className="learnM" to={`/blog${post.fields.slug}`}>
            Continue Reading Article
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
