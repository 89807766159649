import React, { Fragment } from "react";
import { Link } from "gatsby";

const BlogSidebar = ({data}) => {
  const tags = data;

  const kebabCase = string => string
  .replace(/([a-z])([A-Z])/g, "$1-$2")
  .replace(/[\s_]+/g, '-')
  .toLowerCase();

  return (
    <Fragment>
      <aside className="blog-widget">
        <h3 className="blog-widget_title">Tags</h3>
        <div className="blog_tag_widget">
          <ul>
            {tags.map((tag, index) => {
              return (
                <li>
                <Link to={`/tags/${kebabCase(tag.fieldValue)}`}>
                   {tag.fieldValue}
                </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </aside>
    </Fragment>
  );
};

export default BlogSidebar;
